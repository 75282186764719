import React from 'react'
// import Header from '../Header'
import Footer from '../Footer'

export default function PageLayout({children}) {
  return (
    <div className='pageLayout'>
    {/* <Header/> */}
    {children}
    <Footer/>
    </div>
  )
}
