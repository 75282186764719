import React from 'react';
import InstagramIcon from "../../component/Icons/InstagramIcon";
import { Link } from "react-router-dom";
import EmailIcon from "../../component/Icons/EmailIcon";
import "./style.css"

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className='container'>
      <div className="socialmedia">
            <a href="mailto:spiceonwheels@web.de">
            <EmailIcon/>
            </a>
            <Link
              to="https://www.instagram.com/spiceonwheels.de"
              target="_blank"
            >
              {/* <span>For more information please visit here</span> */}
              <InstagramIcon />
            </Link>
            </div>
            </div>
      <div className='copyright'>
        <div className='container'>
       <p> © {currentYear} Spice on wheels</p>
       </div>
      </div>  
    </footer>
  );
}
