import { BrowserRouter } from "react-router-dom";
import NavRouting from "./component/NavRouting";
import"../src/assest/css/style.css"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <NavRouting/>
      </BrowserRouter>
    </div>
  );
}

export default App;
