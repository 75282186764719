import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../../pages/Home'

export default function NavRouting() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="blogs" element={<Blogs />} />
        <Route path="contact" element={<Contact />} /> */}        
      </Routes>     
  )
}
