import React from "react";
import PageLayout from "../../component/PageLayout";
import Logo from "../../component/Icons/Logo";

export default function Home() {
  return (
    <PageLayout>
      <section className="section-main">
        <div className="container">
          <div className="comingsoon">
            <div className="logo-main">
            <Logo />
            </div>
            <h1 className="comingSoontitle">Coming Soon!</h1>
            
          </div>
        </div>
      </section>
    </PageLayout>
  );
}
